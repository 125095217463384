// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import { ArtCard } from '@riseart/art';
import { HorizontalSlider } from '@riseart/slider';
import { useLocale } from 'shared_services/redux/hooks/useLocale';
import { useListTracking } from 'shared_services/redux/hooks/useListTracking';
import { selectUnitSystem } from 'shared_services/redux/selectors/unitSystem';
import { FavoritesToggleMutation } from 'shared_data/providers/queries/FavoritesToggleMutation';
import { dataToListProps } from 'shared_services/riseart/utils/artDataUtils';

const LAZYLOAD_OFFSET_PX = 200;
const COLUMN_TO_ART_SIZES = {
  4: 'slider.gridLarge',
  5: 'slider.gridCommon',
  7: 'slider.gridCommon',
};
const COLUMNS_TO_CARD_STYLE = {
  4: 2,
  7: 5,
};

type Props = {
  columnCount?: number | string,
  displayStyle?: number | string,
  cardDisplayStyle?: number | string,
  artCardProps?: Object,
  items?: Array<Object>,
  sliderProps?: Object,
  actionControls?: Function,
  hideActionControls?: boolean,
  placeholder?: boolean,
  showImagePlaceholder?: boolean,
  tracking?: Object,
  lazyload?: boolean,
  disableHeadingTags?: boolean,
};

/**
 * ArtSlider
 *
 * @param {Props} props
 */
export const ArtSlider = ({
  columnCount = '5',
  displayStyle = '1',
  cardDisplayStyle,
  artCardProps = {},
  items = [],
  sliderProps = {},
  actionControls,
  hideActionControls = false,
  placeholder = false,
  showImagePlaceholder = true,
  tracking,
  lazyload = { once: true, offset: LAZYLOAD_OFFSET_PX },
  disableHeadingTags = false,
}: Props): Node => {
  const { onArtworkClick, ...restArtCardProps } = artCardProps || {};
  const { formatMessage } = useIntl();
  const { handleItemClick, handleVisibilityChange } = useListTracking(
    items,
    !placeholder && tracking,
    onArtworkClick,
  );
  const locale = useLocale();
  const { unitSystem } = useSelector(selectUnitSystem, shallowEqual);
  const requiresVisibilityTracking =
    tracking && tracking.enabled && tracking.enableVisibility && !tracking.enableInitial;
  const VisibilityComponent = requiresVisibilityTracking ? VisibilitySensor : Fragment;
  const visibilityComponentProps = requiresVisibilityTracking
    ? {
        onChange: handleVisibilityChange,
        partialVisibility: true,
        ...((tracking && tracking.visibilityProps) || null),
      }
    : {};

  return (
    // $FlowFixMe
    <VisibilityComponent {...visibilityComponentProps}>
      <HorizontalSlider
        columnCount={columnCount}
        displayStyle={displayStyle}
        {...sliderProps}
        placeholder={placeholder}
      >
        {placeholder
          ? Array.apply(0, Array(parseInt(columnCount, 10) + 1)).map((item, idx) => (
              <ArtCard
                key={idx}
                placeholder
                displayStyle={cardDisplayStyle || COLUMNS_TO_CARD_STYLE[columnCount] || 4}
                disableHeadingTags={disableHeadingTags}
              />
            ))
          : items.map((i) => {
              const {
                id,
                title,
                price,
                artworkUrl,
                artistName,
                artistUrl,
                additionalContent,
                pictureProperties,
                image = {},
              } = dataToListProps(
                i,
                locale,
                COLUMN_TO_ART_SIZES[columnCount],
                formatMessage,
                undefined,
                unitSystem,
              );
              const alt = formatMessage(
                { id: 'components.art.artwork_by_artist' },
                { title, artist: artistName },
              );
              let actionComponents = null;

              if (!hideActionControls) {
                actionComponents =
                  typeof actionControls === 'function' ? (
                    actionControls(i)
                  ) : (
                    <FavoritesToggleMutation artId={id} />
                  );
              }

              return (
                <ArtCard
                  key={id}
                  displayStyle={cardDisplayStyle || COLUMNS_TO_CARD_STYLE[columnCount] || 4}
                  artistName={artistName}
                  artistUrl={artistUrl}
                  linkProps={{ title: alt }}
                  showImagePlaceholder={showImagePlaceholder}
                  pictureProps={{
                    ...pictureProperties,
                    alt,
                    lazyload: lazyload || null,
                    image: {
                      width: image.width,
                      height: image.height,
                    },
                  }}
                  artworkUrl={artworkUrl}
                  artworkTitle={title}
                  price={price}
                  additionalContent={additionalContent}
                  actionControls={actionComponents}
                  {...restArtCardProps}
                  onArtworkClick={handleItemClick(i)}
                  disableHeadingTags={disableHeadingTags}
                />
              );
            })}
      </HorizontalSlider>
    </VisibilityComponent>
  );
};
