// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import find from 'lodash/find';
import { convertUnits } from '@riseart/filter-service';
import {
  Picture,
  PicturePlaceholder,
  generatePicturePropsForCDN,
} from 'shared_components/common/artdirection/picture/Picture';
import { formatCurrency } from 'shared_services/riseart/utils/Utils';
import { ART_DIRECTION_SIZES } from 'shared_models/ArtDirectionSizes';
import { art as ENUM_ART, units as ENUM_UNITS } from 'Enum';
import {
  application as CONFIG_APP,
  artDirection as CONFIG_ART_DIRECTION,
  components as CONFIG_COMPONENTS,
} from 'Config';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { DetailViewInRoomWrapper } from 'shared_components/arts/detail/ViewInRoomWrapper';
import { extractImageFileFromData } from 'shared_services/riseart/utils/ImageUtils';
import { determineViewInRoomBackgrounds } from 'shared_components/arts/viewinroom/helpers';
import { ViewInRoomPreview } from '@riseart/art';

const { art: ART_DIRECTION_CONFIG } = CONFIG_ART_DIRECTION;

/**
 * formatDimensionsLabel
 *
 * @param {Array<number>} dimensions
 * @param {string} units
 * @param {{ type?: 'long' | 'short', precision?: number, separator?: string }} options
 * @returns {string}
 */
export function formatDimensionsLabel(
  dimensions: Array<number>,
  units: string,
  options: {
    type?: 'long' | 'short',
    precision?: number,
    separator?: string,
    convertTo?: string,
    floatIntValues?: boolean,
  } = {},
): string {
  const { type, precision, separator, convertTo, floatIntValues } = {
    type: 'long',
    precision: 1,
    separator: 'x',
    floatIntValues: true,
    ...options,
  };
  const unit = ((units || convertTo) && ENUM_UNITS[(convertTo || units).toLowerCase()].short) || '';
  const dimensionsLabel = dimensions
    .reduce<Array<string>>((accumulator, dimension) => {
      if (dimension) {
        accumulator.push(
          `${(convertTo ? convertUnits(dimension, units, convertTo) : dimension).toFixed(
            !floatIntValues && dimension % 10 === 0 ? 0 : precision,
          )}${type === 'long' ? unit : ''}`,
        );
      }

      return accumulator;
    }, [])
    .join(type === 'long' ? ` ${separator} ` : separator);

  return type === 'long' ? dimensionsLabel : `${dimensionsLabel} ${unit}`;
}

/**
 * renderPicture
 *
 * @param {string} type
 * @param {string} imageType
 * @param {string?} artDirectionKey
 * @param {string?} returnProps
 * @param {string?} includeLazyload
 * @returns {Functioon}
 */
const renderPicture =
  (
    type: string,
    // imageType: string,
    artDirectionKey?: string = 'art.main',
    returnProps?: boolean = false,
    includeLazyload?: boolean = true,
  ): Function =>
  ({ file, style = null, alt, lazyload }: Object) => {
    const pictureProps = {
      type,
      artDirectionKey,
      image: file,
      alt,
      imgProps: { style },
      lazyload: [true, false].indexOf(lazyload) > -1 ? lazyload : includeLazyload,
    };

    return returnProps ? pictureProps : <PicturePlaceholder {...pictureProps} />;
  };

const renderMasterPicture = renderPicture('art.imageActionMain');
const renderOriginalPicture = renderPicture('art.imageActionSecondary');
const renderSquarePicture = renderPicture('gallery.fullWidthThumbnail');
const renderGalleryPictureProps = renderPicture('gallery.fullWidth', 'art.main', true, false);
const renderGalleryPictureZoomedProps = renderPicture('gallery.zoomed', 'art.zoom', true, false);

/**
 * renderWallPreviewPicture
 * @param {Object} data
 */
export function renderWallPreviewPicture(data: Object = {}) {
  const masterImage = find(data.images, { type: ENUM_ART.image.type.TYPE_MAIN_MASTER });

  return (scale: number) => {
    const style = {
      width: `${parseFloat(data.width) * scale}px`,
      height: `${parseFloat(data.height) * scale}px`,
    };

    if (!masterImage) {
      return (
        <div
          style={{
            ...style,
            height: `${parseFloat(data.height) * scale}px`,
            margin: '0 auto',
            backgroundColor: 'gray',
          }}
        />
      );
    }

    return renderOriginalPicture({
      file: masterImage,
      style,
      alt: data.alt,
    });
  };
}

/**
 * renderViewInRoomPicture
 * @param {Object} data
 * @param {string} type
 * @returns {React Node}
 */
export function renderViewInRoomPicture(
  data: Object = {},
  type?: string = ENUM_ART.image.type.TYPE_MAIN_MASTER,
) {
  const masterImage = find(data.images, { type });

  if (!masterImage) {
    return null;
  }

  return renderPicture(
    'art.imageViewInRoom',
    'art.main',
    false,
    false,
  )({
    file: masterImage,
    alt: data.alt,
  });
}

/**
 * renderViewInRoomGridPicture
 *
 * @param {Object} artData
 * @param {string} alt
 * @param {string} imageType
 */
export function renderViewInRoomGridPicture(artData: Object, alt?: string = '') {
  // Do not generate preview if is3D or no image provided
  if (artData.is3D || !artData.image) {
    return null;
  }

  // Select background image
  const backgroundImage = determineViewInRoomBackgrounds(
    artData.width,
    artData.height,
    CONFIG_COMPONENTS.art.viewInRoom.backgrounds.additional,
  )[0];

  return backgroundImage ? (
    <ViewInRoomPreview
      isCover
      config={backgroundImage}
      width={artData.width}
      height={artData.height}
      calcByWidth={false}
      backgroundPicture={({ config, imgProps }) => (
        <FormattedMessage id="components.art.viewInRoomBkgAlt">
          {(alt: string) => (
            <Picture
              type="gallery.fullWidth"
              artDirectionKey="inRoomBackground.main"
              image={config.background}
              alt={alt}
              imgProps={{ ...imgProps }}
            />
          )}
        </FormattedMessage>
      )}
    >
      {renderPicture(
        'art.imageViewInRoom',
        'art.main',
        false,
        false,
      )({
        file: artData.image,
        alt,
      })}
    </ViewInRoomPreview>
  ) : null;
}

/**
 * renderViewInRoomDetailPictures
 * @param {Object} artData
 * @param {string} alt
 */
function renderViewInRoomDetailPictures(
  artData: Object,
  alt: string,
  noWrapper: boolean = false,
  calcByWidth?: boolean = false,
  imageType?: string = 'TYPE_MAIN_MASTER',
) {
  // Do not generate view in room preview for 3d artworks
  if (artData.is3D) {
    return [];
  }

  const mainMasterImage = extractImageFileFromData.artImage(
    (artData && artData.images) || [],
    imageType,
  );
  const backgroundImages = determineViewInRoomBackgrounds(
    artData.width,
    artData.height,
    CONFIG_COMPONENTS.art.viewInRoom.backgrounds.additional,
  );

  return backgroundImages.slice(0, 2).map((previewConfig) => {
    return {
      original: noWrapper ? (
        <ViewInRoomPreview
          config={previewConfig}
          width={artData.width}
          height={artData.height}
          calcByWidth={calcByWidth}
          backgroundPicture={({ config, imgProps }) => (
            <FormattedMessage id="components.art.viewInRoomBkgAlt">
              {(alt: string) => (
                <Picture
                  type="gallery.fullWidth"
                  artDirectionKey="inRoomBackground.main"
                  image={config.background}
                  alt={alt}
                  imgProps={{ ...imgProps }}
                />
              )}
            </FormattedMessage>
          )}
        >
          {renderPicture(
            'art.imageViewInRoom',
            'art.main',
            false,
            false,
          )({
            file: mainMasterImage,
            alt,
          })}
        </ViewInRoomPreview>
      ) : (
        <DetailViewInRoomWrapper
          image={mainMasterImage}
          previewConfig={previewConfig}
          artWidth={artData.width}
          artHeight={artData.height}
          calcByWidth={calcByWidth}
        >
          {renderPicture(
            'art.imageViewInRoom',
            'art.main',
            false,
            false,
          )({
            file: mainMasterImage,
            alt,
          })}
        </DetailViewInRoomWrapper>
      ),
      thumbnail: previewConfig.thumbnail,
    };
  });
}

/**
 * prepareGalleryImages
 * @param {Object} data
 * @param {Function} formatMessage
 */
export function prepareGalleryImages(data: Object = {}, formatMessage: Function): Array<?Object> {
  const rootImages = get(data, 'images');
  const alt = formatMessage(
    { id: 'components.art.artwork_by_artist' },
    { title: data.title, artist: data.artistName },
  );

  return rootImages.reduce(
    (accumulator, image) => {
      if (image.type === ENUM_ART.image.type.TYPE_MAIN_MASTER) {
        const mainMasterImage = extractImageFileFromData.artImage(image, 'TYPE_MAIN_MASTER', true);
        accumulator = [
          {
            original: renderGalleryPictureProps({
              file: mainMasterImage,
              alt,
            }),
            zoomed: renderGalleryPictureZoomedProps({
              file:
                extractImageFileFromData.artImage(image, 'TYPE_MAIN_ORIGINAL', true) ||
                mainMasterImage,
              alt,
            }),
            thumbnail: renderSquarePicture({
              file: extractImageFileFromData.artImage(image, 'TYPE_MAIN_SQUARE'),
              alt,
            }),
          },
          ...accumulator,
        ];
      } else if (image.type === ENUM_ART.image.type.TYPE_DETAIL_MASTER) {
        const detailMasterImage = extractImageFileFromData.artImage(
          image,
          'TYPE_DETAIL_MASTER',
          true,
        );
        accumulator = [
          ...accumulator,
          {
            original: renderGalleryPictureProps({
              file: detailMasterImage,
              alt,
            }),
            zoomed: renderGalleryPictureZoomedProps({
              file:
                extractImageFileFromData.artImage(image, 'TYPE_DETAIL_ORIGINAL', true) ||
                detailMasterImage,
              alt,
            }),
            thumbnail: renderSquarePicture({
              file: extractImageFileFromData.artImage(image, 'TYPE_DETAIL_SQUARE'),
              alt,
            }),
          },
        ];
      }
      return accumulator;
    },
    [
      ...renderViewInRoomDetailPictures(data, alt, true).map((item) => ({
        original: item.original,
        thumbnail: renderSquarePicture({ file: item.thumbnail, alt: '' }),
      })),
    ],
  );
}

/**
 * prepareImages
 * @param {Object} data
 * @param {Function} formatMessage
 */
export function prepareImages(data: Object = {}, formatMessage: Function): Array<?Object> {
  const rootImages = get(data, 'images');
  const alt = formatMessage(
    { id: 'components.art.artwork_by_artist' },
    { title: data.title, artist: data.artistName },
  );

  return rootImages.reduce(
    (accumulator, image, key) => {
      // Do not lazyload first image
      const shouldLazyloadImage = key !== 0;

      if (image.type === ENUM_ART.image.type.TYPE_MAIN_MASTER) {
        accumulator = [
          {
            original: renderMasterPicture({
              file: extractImageFileFromData.artImage(image, 'TYPE_MAIN_MASTER', true),
              alt,
              lazyload: shouldLazyloadImage,
            }),
            thumbnail: renderSquarePicture({
              file: extractImageFileFromData.artImage(image, 'TYPE_MAIN_SQUARE'),
              alt,
              lazyload: shouldLazyloadImage,
            }),
          },
          ...accumulator,
        ];
      } else if (image.type === ENUM_ART.image.type.TYPE_DETAIL_MASTER) {
        accumulator = [
          ...accumulator,
          {
            original: renderMasterPicture({
              file: extractImageFileFromData.artImage(image, 'TYPE_DETAIL_MASTER', true),
              alt,
              lazyload: shouldLazyloadImage,
            }),
            thumbnail: renderSquarePicture({
              file: extractImageFileFromData.artImage(image, 'TYPE_DETAIL_SQUARE'),
              alt,
              lazyload: shouldLazyloadImage,
            }),
          },
        ];
      }
      return accumulator;
    },
    [
      ...renderViewInRoomDetailPictures(data, alt, true, true).map((item) => ({
        original: item.original,
        thumbnail: renderSquarePicture({ file: item.thumbnail, alt }),
      })),
    ],
  );
}

export function renderNotFoundArtImage(image: Object, alt: string = '') {
  return renderMasterPicture({
    file: extractImageFileFromData.artImage(image, 'TYPE_MAIN_MASTER', true),
    alt,
  });
}

/**
 * getMasterImage
 *
 * @param {Object} data
 */
export function getMasterImage(data: Object = {}): ?Object {
  return extractImageFileFromData.artImage(get(data, 'images'), 'TYPE_MAIN_MASTER');
}

/**
 * dataToBasicArtProps
 *
 * @param {Object} data
 * @param {Object} locale
 * @param {string} artDirectionSizeType
 * @param {string} artDirectionConfigKey
 */
export function dataToBasicArtProps(
  data: Object,
  locale: Object,
  artDirectionSizeType: string,
  artDirectionConfigKey?: string,
) {
  const {
    id,
    title,
    canBuy,
    skuBuyPrice,
    medium,
    width,
    height,
    artistName,
    unit,
    slug,
    storeCode,
    ...restData
  } = data;
  const artImage =
    data.artImage ||
    (data.images &&
      (extractImageFileFromData.artImage(data.images, 'TYPE_MAIN_SQUARE', true) ||
        extractImageFileFromData.artImage(data.images, 'TYPE_FLAT_SQUARE')));

  return {
    ...restData,
    id,
    title,
    artworkUrl: UrlAssembler.artDetail(id, slug, { locale }),
    artistUrl: UrlAssembler.artistProfile(restData.artistId, restData.artistAlias, { locale }),
    artistName,
    image: artImage,
    pictureProperties:
      artImage &&
      generatePicturePropsForCDN({
        image: artImage,
        sizesList: get(ART_DIRECTION_SIZES, artDirectionSizeType),
        artDirectionConfig:
          (artDirectionConfigKey && ART_DIRECTION_CONFIG[artDirectionConfigKey]) ||
          ART_DIRECTION_CONFIG.main,
      }),
  };
}

/**
 * dataToListProps
 * @param {Object} data
 * @param {Object} locale
 * @param {string} artDirectionSizeType
 * @param {Function} formatMessage
 * @param {string} artDirectionConfigKey
 * @param {string} unitSystem
 */
export function dataToListProps(
  data: Object,
  locale: Object,
  artDirectionSizeType: string,
  formatMessage: Function,
  artDirectionConfigKey?: string,
  unitSystem: ?string = CONFIG_APP.units.defaultSystem,
) {
  const {
    id,
    title,
    canBuy,
    skuBuyPrice,
    medium,
    width,
    height,
    unit,
    slug,
    storeCode,
    ...restData
  } = data;

  const lengthUnit = CONFIG_APP.units[unitSystem].length;
  return {
    ...restData,
    ...dataToBasicArtProps(data, locale, artDirectionSizeType, artDirectionConfigKey),
    additionalContent: `${
      medium ? `${formatMessage({ id: `filters.art.medium.${medium}` })} - ` : ''
    }${formatDimensionsLabel([height, width], unit, {
      type: 'short',
      precision: lengthUnit.precision.low || 0,
      convertTo: lengthUnit.unit,
    })}`,
    price:
      canBuy && skuBuyPrice
        ? formatCurrency(skuBuyPrice, storeCode, undefined, {
            locale: locale && locale.name,
            precision: 0,
          })
        : null,
  };
}

/**
 * normalizeRawArtData
 *
 * @param {Object} rawData
 * @param {string} storeCode
 * @param {Object} locale
 * @param {string} unitSystem
 */
export function normalizeRawArtData(
  rawData: Object = {},
  storeCode: string | null | void,
  locale: Object,
  unitSystem: string = CONFIG_APP.units.defaultSystem,
) {
  const {
    id,
    title,
    artist: {
      id: artistId,
      userId: artistUserId,
      userAvatars: artistUserAvatars,
      alias: artistAlias,
      role: artistRole,
      name: artistName,
      statement: artistStatement,
    },
    isProduct,
    is3D,
    productId,
    productPrimarySku,
    productType,
    productCanBuy,
    productImages,
    images,
    units: rootUnits,
    width: rootWidth,
    height: rootHeight,
    depth: rootDepth,
    medium,
    subMedium,
    subject,
    subSubject,
    style,
    materials,
    year,
    description,
    showDescription,
  } = rawData;
  const lengthUnit = CONFIG_APP.units[unitSystem].length;
  const productData = productPrimarySku || {};
  const mergedData = {
    units: productData.units || rootUnits || '',
    height: productData.height || rootHeight,
    width: productData.width || rootWidth,
    depth: productData.depth || rootDepth,
  };
  const productStoreData = find(productData.stores, { storeCode }) || {};
  const unit = ENUM_UNITS[mergedData.units.toLowerCase()].short;
  const artworkDimensionsLabel = formatDimensionsLabel(
    [mergedData.height, mergedData.width, mergedData.depth],
    mergedData.units,
    {
      type: 'short',
      separator: ' x ',
      convertTo: lengthUnit.unit,
      precision: typeof lengthUnit.precision.high !== 'undefined' ? lengthUnit.precision.high : 1,
      floatIntValues: true,
    },
  );

  return {
    ...mergedData,
    id,
    title,
    images,
    isProduct,
    is3D,
    productImages,
    productId,
    artistId,
    artistUserId,
    artistUserAvatars,
    artistAlias,
    artistRole,
    artistName,
    artistStatement,
    artistUrl: UrlAssembler.artistProfile(artistId, artistAlias, { locale }),
    unit,
    artworkShortDimensionsLabel: formatDimensionsLabel(
      [
        mergedData.height,
        mergedData.width,
        ...(is3D && mergedData.depth ? [mergedData.depth] : []),
      ],
      mergedData.units,
      {
        type: 'short',
        separator: ' x ',
        convertTo: lengthUnit.unit,
        precision: typeof lengthUnit.precision.high !== 'undefined' ? lengthUnit.precision.high : 1,
        floatIntValues: true,
      },
    ),
    artworkDimensionsLabel,
    medium,
    subMedium,
    subject,
    subSubject,
    style,
    materials,
    year: productData.year || year,
    mediumWithSubMedium: [
      { key: 'medium', value: medium },
      { key: 'subMedium', value: subMedium },
    ],
    subjectWithSubSubject: [
      { key: 'subject', value: subject },
      { key: 'subSubject', value: subSubject },
    ],
    styleStructured: style && [{ key: 'style', value: style }],
    editionSize: productData.editionSize,
    framed: productData.framed && { description: productData.frameDescription },
    signed: productData.signed && { description: productData.signedLocation },
    type: productType,
    readyToHang: !!productData.readyToHang,
    shipsFromCode: productData.shipsFromCode,
    shipsFramedBy: productData.shipsFramedBy,
    shipsBy: productData.shipsBy,
    productPrimarySku: productData,
    productStore: productStoreData,
    productCanBuy,
    description,
    showDescription,
  };
}

/**
 * recentArtworksMapper
 *
 * @param {Array<Object>} items
 * @param {number} daysLimit
 * @param {Array<string>} omitIds
 * @returns {Function}
 */
export const recentArtworksMapper =
  (daysLimit: number, omitIds?: Array<string>): Function =>
  (items: Array<Object> = []): Array<Object> => {
    if (!items || !items.length) {
      return [];
    }

    const currentTime = Date.now();
    const storageLimit = daysLimit * 24 * 60 * 60 * 1000;

    return items.filter(
      (item) =>
        item &&
        (!omitIds || (omitIds && omitIds.indexOf(item.id) === -1)) &&
        item.dateAddedInStorage + storageLimit > currentTime,
    );
  };

/**
 * translateFrameOption
 *
 * @param {string} title
 * @param {Function} formatMessage
 * @returns {{colorKey: string, title: string}}
 */
export function translateFrameOption(
  title: string,
  formatMessage: Function,
): { colorKey: string, title: string } {
  const frameKey =
    typeof title === 'string' ? title.trim().replace(/ +/g, '_').toLowerCase() : title;

  return {
    colorKey: frameKey,
    title: formatMessage({
      id: `components.art.options.frame.${frameKey}`,
      defaultMessage: title,
    }),
  };
}
