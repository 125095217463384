// @flow

import { useState, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { cart as ENUM_CART } from 'Enum';
import { useQuery } from 'shared_services/apollo/useQuery';
import { selectVisitorProperty, selectVisitorId } from 'shared_services/redux/selectors/visitor';
import { selectStoreCode } from 'shared_services/redux/selectors/storeCode';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import READ_CART_QUERY from 'shared_data/queries/cart/read.graphql';

type CartDataType = {
  cart: Object,
  cartItems: Array<Object>,
  cartType: string,
  visitorId: ?(string | number),
  storeCode: ?string,
  handleLoading: Function,
  queryRequest: Object,
  loading: boolean,
};

/**
 * useCart
 *
 * @param {string} cartTypeFromProps
 * @param {boolean} forceRefetch
 * @returns {CartDataType}
 */
export function useCart(cartTypeFromProps?: string, forceRefetch?: boolean = true): CartDataType {
  const { cartType: cartTypeFromUrl } = useParams();
  const cartType =
    cartTypeFromProps ||
    (cartTypeFromUrl &&
    LocalizedConfig.get(`components.cart.routeToType.${cartTypeFromUrl}`) ===
      ENUM_CART.type.TYPE_RENT
      ? ENUM_CART.type.TYPE_RENT
      : ENUM_CART.type.TYPE_BUY);
  const isInitialLoad = useRef(true);
  const [loading, setLoading] = useState(false);

  // Redux store
  const authStateVisitorId = useSelector(selectVisitorId);
  const meStateVisitorId = useSelector(selectVisitorProperty('id'));
  const storeCode = useSelector(selectStoreCode);
  const visitorId = useMemo(() => {
    if (!meStateVisitorId) {
      return null;
    }

    const authVisitorId = parseInt(authStateVisitorId, 10);
    const meVisitorId = parseInt(meStateVisitorId, 10);

    const isVisitorSynced =
      (!authVisitorId && meVisitorId) || (authVisitorId && authVisitorId === meVisitorId);

    return isVisitorSynced ? meVisitorId : null;
  }, [authStateVisitorId, meStateVisitorId]);

  const {
    loading: loadingFromQuery,
    error,
    data,
  } = useQuery(READ_CART_QUERY, {
    skip: !storeCode || !visitorId,
    variables: { cartId: cartType, store: storeCode, visitorId },
    ...(isInitialLoad.current && forceRefetch ? { fetchPolicy: 'network-only' } : {}),
  });

  const { readCart: responseData = {} } = data || {};
  const { cartItems = [], ...restData } = responseData;

  if (data || error) {
    isInitialLoad.current = false;
  }

  return {
    visitorId: visitorId || null,
    storeCode: visitorId ? storeCode : null,
    queryRequest: { loading: loadingFromQuery, error },
    loading,
    cart: restData,
    cartItems,
    cartType,
    handleLoading: setLoading,
  };
}
