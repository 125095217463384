// @flow

import { store as CONFIG_STORE } from 'Config';
import { GUI_PROPERTIES } from 'shared_models/Gui';

/**
 * selectGui
 *
 * @param {Object} state
 * @returns {?Object}
 */
export function selectGui(state: Object): ?Object {
  const gui = state[CONFIG_STORE.keys.gui];

  return gui || null;
}

/**
 * selectGuiCartSidebar
 *
 * @param {Object} state
 * @returns {?Object}
 */
export function selectGuiCartSidebar(state: Object): ?Object {
  const gui = state[CONFIG_STORE.keys.gui];

  return (gui && gui[GUI_PROPERTIES.CART_SIDEBAR]) || null;
}

/**
 * selectGuiRecentArtWidget
 *
 * @param {Object} state
 * @returns {Object}
 */
export function selectGuiRecentArtWidget(state: Object): ?Object {
  const gui = state[CONFIG_STORE.keys.gui];

  return gui ? gui[GUI_PROPERTIES.RECENT_ART_WIDGET] : null;
}
