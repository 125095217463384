// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import { useIntl } from 'react-intl';
import { GiftBoxIcon } from '@riseart/icons';
import { MediaQuery } from '@riseart/common';

import { raScreenLg } from '@riseart/antd-provider/dist/website/variables.less';
import {
  xmaxCls,
  xmaxIconCls,
  xmaxContentCls,
  xmaxContentMainCls,
} from 'shared_components/messages/christmas/styles.less';

/**
 * ChristmasDelivery
 *
 * @param {Props} props
 * @returns {Node}
 */
export const ChristmasDelivery = (): Node => {
  const intl = useIntl();

  const formattedHTMLTags = { strong: (chunks) => <strong>{chunks}</strong> };

  return (
    <div className={xmaxCls}>
      <div className={xmaxIconCls}>
        <GiftBoxIcon />
      </div>
      <div className={xmaxContentCls}>
        <MediaQuery minWidth={raScreenLg}>
          {(isLarge) => (
            <Fragment>
              <span
                className={xmaxContentMainCls}
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(
                    {
                      id: 'messages.application.christmasDelivery.line1',
                    },
                    { ...formattedHTMLTags },
                  ),
                }}
              />
              {isLarge ? ' - ' : ''}
              <span
                className={xmaxContentCls}
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: `messages.application.christmasDelivery.${
                      isLarge ? 'line2' : 'line2Truncated'
                    }`,
                  }),
                }}
              />
            </Fragment>
          )}
        </MediaQuery>
      </div>
    </div>
  );
};
