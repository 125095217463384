// @flow

import React, { Component } from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from '@apollo/client/react/hoc';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import { connect } from 'react-redux';
import { AnimatedButton } from '@riseart/common';
import { BagIcon } from '@riseart/icons';
import { cart as ENUM_CART } from 'Enum';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import { selectVisitorId } from 'shared_services/redux/selectors/visitor';
import { selectAuthShippingCountryCode } from 'shared_services/redux/selectors/auth';
import { selectStoreCode } from 'shared_services/redux/selectors/storeCode';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { GUI_PROPERTIES } from 'shared_models/Gui';
import { guiUpdate } from 'shared_services/redux/actions/application/gui';
import { getGraphqlOperationName, cacheUpdateHandler } from 'shared_services/apollo/helpers';
import { cartItemsActionFactory } from 'shared_services/redux/actions/cart/cart';
import { HOCCartErrors } from 'shared_hocs/cart/Errors';
import READ_CART_QUERY from 'shared_data/queries/cart/read.graphql';
import ADD_CART_ITEM_MUTATION from 'shared_data/queries/cart/item/add.graphql';

type Props = {
  cartId: ENUM_CART.type.TYPE_BUY | ENUM_CART.type.TYPE_RENT,
  isLoading: boolean,
  history: Object,
  openSidebar?: boolean,
  addCartItem: Function,
  actionUpdateCartItem: Function,
  actionOpenSidebar: Function,
  actionErrorAdd: Function,
  actionJSErrorAdd: Function,
  onLoading: Function,
  onSuccess: Function,
  onError: Function,
  onClick: Function,
  children: Node,
  locale: Object,
};

type State = {
  isLoading: boolean,
};

/**
 * AddCartItem
 *
 * @param {Props} props
 */
class AddCartItem extends Component<Props, State> {
  static defaultProps: Object = {
    openSidebar: true,
  };

  constructor(props) {
    super(props);

    this.state = { isLoading: this.props.isLoading || false };
    this.bindMethod();
  }

  /**
   * handleClick
   */
  handleClick(options: Object = null) {
    if (this.state.isLoading) {
      return;
    }

    const { addCartItem, onSuccess, onClick } = this.props;

    if (typeof onClick === 'function') {
      onClick(this.props);
    }

    this.handleLoading(true);
    addCartItem(options)
      .then(({ data }) => {
        this.handleLoading(false);

        if (data && data.addCartItem) {
          if (typeof onSuccess === 'function') {
            onSuccess(data.addCartItem);
          } else {
            this.handleOnSuccess();
          }

          this.props.actionErrorAdd(
            (data && data.addCartItem && data.addCartItem.cart && data.addCartItem.cart.messages) ||
              [],
          );
          this.props.actionUpdateCartItem(data.addCartItem);
        }
      })
      .catch(this.handleError);
  }

  /**
   * bindMethod
   */
  bindMethod() {
    this.handleClick = this.handleClick.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleLoading = this.handleLoading.bind(this);
    this.handleOnSuccess = this.handleOnSuccess.bind(this);
  }

  /**
   * handleOnSuccess
   */
  handleOnSuccess: Function;

  handleOnSuccess() {
    const { history, cartId, actionOpenSidebar, openSidebar } = this.props;

    if (cartId === ENUM_CART.type.TYPE_BUY && openSidebar) {
      actionOpenSidebar();
    }

    if (cartId === ENUM_CART.type.TYPE_RENT) {
      history.push(UrlAssembler.cart(ENUM_CART.type.TYPE_RENT));
    }
  }

  /**
   * handleError
   */
  handleError: Function;

  handleError(error: Object) {
    const { actionJSErrorAdd, onError } = this.props;

    actionJSErrorAdd(error);
    this.handleLoading(false);

    if (onError && typeof onError === 'function') {
      onError(error);
    }
  }

  /**
   * handleLoading
   *
   * @param {bolean} isLoading
   */
  handleLoading: Function;

  handleLoading(isLoading: boolean) {
    this.setState({ isLoading });

    if (typeof this.props.onLoading === 'function') {
      this.props.onLoading(isLoading);
    }
  }

  /**
   * render
   */
  render() {
    const { children, isLoading } = this.props;

    return children && typeof children === 'function' ? (
      children({ loading: this.state.isLoading, onClick: this.handleClick })
    ) : (
      <FormattedMessage id="components.art.add_to_basket">
        {(label: string) => (
          <AnimatedButton
            icon={<BagIcon />}
            isLoading={isLoading}
            onClick={this.handleClick}
            title={label}
          />
        )}
      </FormattedMessage>
    );
  }
}

/**
 * withAddCartItem
 */
const withAddCartItem: Function = graphql(ADD_CART_ITEM_MUTATION, {
  props: ({ ownProps, mutate }) => ({
    addCartItem(options?: Object) {
      const variables = {
        cartId: ownProps.cartId,
        visitorId: ownProps.visitorId,
        store: ownProps.storeCode,
      };

      return mutate({
        variables: {
          skuId: ownProps.skuId,
          qty: ownProps.qty,
          ...(ownProps.options ? { options: ownProps.options } : {}),
          ...(options && options.insurance ? { options: options.insurance } : {}),
          shippingCountryCode: ownProps.shippingCountryCode,
          ...variables,
        },
        update: cacheUpdateHandler(
          READ_CART_QUERY,
          getGraphqlOperationName(ADD_CART_ITEM_MUTATION),
          variables,
        ),
      });
    },
  }),
});

/**
 * AddCartItemButton
 */
export const AddCartItemButton = connect(
  (state) => ({
    visitorId: selectVisitorId(state),
    storeCode: selectStoreCode(state),
    shippingCountryCode: selectAuthShippingCountryCode(state),
    locale: localeSelector(state),
  }),
  (dispatch) => ({
    actionUpdateCartItem: cartItemsActionFactory(dispatch),
    actionOpenSidebar: (opened = true, trigger = ENUM_CART.sidebar.trigger.TRIGGER_ADD_CART_BTN) =>
      dispatch(guiUpdate(GUI_PROPERTIES.CART_SIDEBAR, { opened, trigger })),
  }),
)(withAddCartItem(withTranslatedRouter(HOCCartErrors(AddCartItem))));
