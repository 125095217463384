// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Hint, Paragraph, LazyloadPlaceholder } from '@riseart/common';
import { formatCurrency } from 'shared_services/riseart/utils/Utils';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { artDirection as CONFIG_ART_DIRECTION, application as CONFIG_APP } from 'Config';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import {
  formatDimensionsLabel,
  translateFrameOption,
} from 'shared_services/riseart/utils/artDataUtils';
import { ShipsFrom } from 'shared_components/arts/fragments/ShipsFrom';
import { OfferModal } from 'shared_components/common/modal/Offer';
import { generatePicturePropsForCDN } from 'shared_components/common/artdirection/picture/Picture';
import { extractImageFileFromData } from 'shared_services/riseart/utils/ImageUtils';
import { ART_DIRECTION_SIZES } from 'shared_models/ArtDirectionSizes';
import { TRANSLATIONS as FRAME_TRANSLATIONS } from 'shared_components/arts/translations/frames';

const { main: ART_DIRECTION_CONFIG } = CONFIG_ART_DIRECTION.art;
const { signs: CURRENCY_SIGNS } = CONFIG_APP.i18n.currency;

/**
 * getPicturePropsFromImages
 *
 * @param {Object} data
 * @param {boolean} useLazyload
 * @param {Object} artDirectionSizes
 * @param {Object}
 */
function getPicturePropsFromImages(
  { artTitle, artImages }: Object,
  useLazyload?: boolean = false,
  artDirectionSizes = ART_DIRECTION_SIZES.cart.item,
): Object {
  const artImage = extractImageFileFromData.artImage(artImages, 'TYPE_MAIN_SQUARE');

  return {
    ...(artImage &&
      generatePicturePropsForCDN({
        image: artImage,
        sizesList: artDirectionSizes,
        artDirectionConfig: ART_DIRECTION_CONFIG,
      })),
    alt: artTitle,
    image: {
      width: artImage.width,
      height: artImage.height,
    },
    lazyload: useLazyload
      ? {
          height: 135,
          offset: 200,
          placeholder: <LazyloadPlaceholder height={135} />,
        }
      : null,
  };
}

/**
 * getInsuranceOption
 *
 * @param {Object} item
 * @param {string} storeCode
 * @param {string} localeCode
 * @returns {Object}
 */
function getInsuranceOption(item: Object, storeCode: string, localeCode: string): Object {
  if (!item || !item.hasInsuranceOptions) {
    return null;
  }

  const option = item.options.filter(({ isInsurance }) => isInsurance === true)[0];

  if (!option) {
    return null;
  }

  const optionValue = (option && option.values && option.values[0]) || null;
  return {
    actionLabel: (
      <FormattedMessage
        id={
          optionValue && optionValue.selected
            ? 'components.art.includesOption'
            : 'components.art.addOption'
        }
      />
    ),
    hint: (
      <Hint
        type="hint"
        padded
        wrapperTag="span"
        showOnClick
        placement="N"
        text={
          <div>
            <FormattedMessage tagName="strong" id="components.art.options.insuranceHintTitle" />
            <FormattedMessage id="common.learnMore">
              {(hintLinkText: string) => (
                <FormattedMessage
                  id="components.art.options.insuranceHintText"
                  values={{
                    percentage: 10,
                    value: formatCurrency(100, storeCode, undefined, {
                      locale: localeCode,
                    }),
                  }}
                >
                  {(hintText: string) => (
                    <Paragraph>
                      {hintText}{' '}
                      <FormattedMessage id="components.art.options.rentHelpTitle">
                        {(rentHelpTitle: string) => (
                          <Link
                            external
                            target="_blank"
                            href={LocalizedConfig.get('externalUrls.rentHelp')}
                            title={rentHelpTitle}
                          >
                            {hintLinkText}
                          </Link>
                        )}
                      </FormattedMessage>
                    </Paragraph>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </div>
        }
      />
    ),
    id: option.id,
    title: option.title,
    value: optionValue
      ? {
          id: optionValue.id,
          selected: optionValue.selected,
          price: optionValue.price,
          priceLabel: formatCurrency(optionValue.price, storeCode, undefined, {
            locale: localeCode,
          }),
        }
      : {},
    selected: item.hasSelectedInsuranceOptions,
  };
}

/**
 * getFrameOptions
 *
 * @param {Object} item
 * @param {string} storeCode
 * @param {Function} formatMessage
 * @returns {Object}
 */
function getFrameOptions(
  item: Object,
  storeCode: string,
  formatMessage: Function,
  localeCode,
): Object {
  let selectedFrame = {};
  const frameOption =
    item && item.options ? item.options.filter(({ isFrame }) => isFrame === true)[0] : null;
  const frames: Array<Object> = frameOption
    ? frameOption.values.map(({ id, selected, price, title }) => {
        if (selected) {
          selectedFrame = { id, title, price };
        }

        return {
          ...translateFrameOption(title, formatMessage),
          id,
          price,
          priceLabel: price
            ? formatCurrency(price, storeCode, undefined, {
                locale: localeCode,
              })
            : null,
        };
      })
    : [];

  return {
    frameOptionsId: frameOption && frameOption.id,
    selectedFrame,
    canBeFramed: item.hasFrameOptions,
    isFramed: item.framed,
    readyToHang: item.readyToHang,
    frames,
  };
}

/**
 * mapCartDataToProps
 *
 * @param {Object} item
 * @param {Object} locale
 * @param {boolean} iseLazyload
 * @param {Object} artDirectionSizes
 * @param {Function} formatMessage
 * @returns {Object}
 */
export function mapCartDataToProps(
  { item, storeCode, unitSystem, isCartRental }: Object,
  locale: Object,
  useLazyload: boolean = false,
  formatMessage: Function,
  artDirectionSizes: Object = ART_DIRECTION_SIZES.cart.item,
): Object {
  const lengthUnit = unitSystem ? CONFIG_APP.units[unitSystem].length : null;
  return {
    id: item.id,
    key: item.key,
    storeCode,
    currencySign: CURRENCY_SIGNS[storeCode],
    artId: item.artId,
    title: item.artTitle,
    url:
      item.artId && item.artSlug
        ? UrlAssembler.artDetail(item.artId, item.artSlug, { locale })
        : null,
    artistName: item.artistName || null,
    artistUrl: UrlAssembler.artistProfile(item.artistId, item.artistAlias, { locale }),
    allowQtyChange: isCartRental ? false : item.productIsEdition,
    qty: item.qty,
    price:
      item.total !== undefined && item.total !== null
        ? formatCurrency(item.total, storeCode, undefined, { locale: locale && locale.name })
        : null,
    discount: item.offerId ? <OfferModal id={item.offerId} /> : null,
    rentalDuration: isCartRental
      ? item.rentalPeriod === 'month' && (
          <FormattedMessage
            id="components.cart.rentalMonthDuration"
            values={{ months: item.rentalFrequency }}
          >
            {(label: string) => ` | ${label}`}
          </FormattedMessage>
        )
      : null,
    images: item.artImages,
    additional: (
      <React.Fragment>
        {item.shipsBy && item.shipsFromCode ? (
          <ShipsFrom type="long" date={item.shipsBy} countryCode={item.shipsFromCode} />
        ) : null}
        {item.incursCustomsDuties ? (
          <React.Fragment>
            {' '}
            {/* eslint-disable-line */}
            <FormattedMessage
              id="components.cart.customCharges"
              values={{
                link: (
                  <FormattedMessage id="common.here">
                    {(here: string) => (
                      <FormattedMessage id="components.cart.customChargesTitle">
                        {(customChargesTitle: string) => (
                          <Link
                            external
                            target="_blank"
                            href={LocalizedConfig.get('externalUrls.customsChargesHelp')}
                            title={customChargesTitle}
                          >
                            {here}
                          </Link>
                        )}
                      </FormattedMessage>
                    )}
                  </FormattedMessage>
                ),
              }}
            />
          </React.Fragment>
        ) : null}
      </React.Fragment>
    ),
    details: (
      <React.Fragment>
        {/* eslint-disable-next-line */}
        {item.productIsEdition && item.editionSize ? (
          <FormattedMessage
            id="components.art.edition_of"
            values={{ editionSize: item.editionSize }}
          >
            {(editionLabel: string) => `${editionLabel} | `}
          </FormattedMessage>
        ) : item.artMedium ? (
          <FormattedMessage
            id={`filters.art.medium.${item.artMedium}`}
            defaultMessage={item.artMedium}
          >
            {(medium: string) => `${medium} | `}
          </FormattedMessage>
        ) : null}
        {lengthUnit
          ? formatDimensionsLabel([item.height, item.width, item.depth], item.units, {
              convertTo: lengthUnit.unit,
              precision: lengthUnit.precision.low || 0,
            })
          : null}
      </React.Fragment>
    ),
    frameOptions: getFrameOptions(item, storeCode, formatMessage, locale && locale.name),
    insurance: getInsuranceOption(item, storeCode, locale && locale.name),
    pictureProps: getPicturePropsFromImages(item, useLazyload, artDirectionSizes),
  };
}

/**
 * mapOrderDataToProps
 *
 * @param {Object} item
 * @param {Object} locale
 * @param {boolean} iseLazyload
 * @param {Function} formatMessage
 * @param {Object} artDirectionSizes
 * @returns {Object}
 */
export function mapOrderDataToProps(
  { item, storeCode, unitSystem = CONFIG_APP.units.defaultSystem, isCartRental }: Object,
  locale: Object,
  useLazyload: boolean = false,
  formatMessage: Function,
  artDirectionSizes: Object = ART_DIRECTION_SIZES.cart.item,
): Object {
  // convert specific order item properties
  const orderItem = {
    ...item,
    artTitle: item.artTitle || item.name,
    // list all frame options as selected
    options: item.options.map((option) => {
      return {
        ...option,
        ...(option && option.isFrame && option.values
          ? { values: option.values.map((value) => ({ ...value, selected: true })) }
          : null),
      };
    }),
  };
  return mapCartDataToProps(
    { item: orderItem, storeCode, unitSystem, isCartRental },
    locale,
    useLazyload,
    formatMessage,
    artDirectionSizes,
  );
}

/**
 * getStaticCartItemProps
 *
 * @param {boolean} isCartRental
 * @returns {Object}
 */
export function getStaticCartItemProps(isCartRental: boolean): Object {
  return {
    translations: {
      by: <FormattedMessage id="common.by">{(by: string) => by}</FormattedMessage>,
      qty: <FormattedMessage id="components.cart.qty" />,
      frames: {
        ...FRAME_TRANSLATIONS,
        ...(isCartRental
          ? {
              shipsFramedTooltipHeading: (
                <FormattedMessage id="components.cart.shipsReadyToHangTooltipHeading" />
              ),
              shipsFramedTooltipDescription: (
                <FormattedMessage id="components.cart.shipsReadyToHangTooltipDescription" />
              ),
              readyToHangTooltipHeading: (
                <FormattedMessage id="components.cart.shipsReadyToHangTooltipHeading" />
              ),
              readyToHangTooltipDescription: (
                <FormattedMessage id="components.cart.shipsReadyToHangTooltipDescription" />
              ),
            }
          : {}),
      },
      apply: <FormattedMessage id="common.apply" />,
      remove: <FormattedMessage id="common.remove" />,
    },
  };
}

/**
 * parseCardDate
 *
 * @param {string} value
 * @returns {{ month: number, year: number }}
 */
export function parseCardDate(value: string): { month: ?number, year: ?number } {
  const currentData = new Date();
  const [month, year] = value ? value.replace(/\s+/g, '').split('/') : [];

  return {
    month: month ? parseInt(month, 10) : null,
    year: year ? parseInt(currentData.getFullYear() / 100, 10) * 100 + parseInt(year, 10) : null,
  };
}
