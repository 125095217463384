// @flow

import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ArrowUpLightIcon, ArrowLightIcon } from '@riseart/icons';
import { Link, MediaQuery, Button } from '@riseart/common';
import { convertUTCToLocal } from '@riseart/fe-utils';
import { cookies as CONFIG_COOKIES, application as CONFIG_APP } from 'Config';
import { useLocale } from 'shared_services/redux/hooks/useLocale';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { Cookies } from 'shared_services/riseart/utils/Cookies/Cookies';
import { Countdown } from 'shared_components/common/countdown/Countdown';

import { raScreenSmMax } from '@riseart/antd-provider/dist/website/variables.less';
import {
  blackFridayCls,
  blackFridayWithCounterCls,
  blackFridayLightCls,
  blackFridayCondensedCls,
  blackFridayTitleCls,
  blackFridayTitleLabelCls,
  blackFridaySubTitleLabelCls,
  blackFridayContentCls,
  blackFridayContentCellCls,
  blackFridayCountdownCls,
  blackFridayCountdownLabelCls,
  blackFridayCountdownCloseCls,
} from 'shared_components/messages/BlackFriday.less';

type Props = {
  date: string,
  localTime: boolean,
  extended?: boolean,
  storeCode: ?string,
  onHeightChange: Function,
  link: Object,
};

/**
 * ArtistSpotlightMessage
 *
 * @param {Props} props
 * @returns {Node}
 */
export const ArtistSpotlightMessage = ({
  date,
  localTime = false,
  extended = false,
  storeCode,
  onHeightChange,
  link = null,
}: Props): Node => {
  const hasCounter = !!date;
  const locale = useLocale();
  const linkProps = link[locale.name];
  const [hideCodes, setHideCodes] = useState(
    Cookies.get(CONFIG_COOKIES.artistSpotlightCountdownSession.name) === 'true',
  );
  const currencySign =
    CONFIG_APP.i18n.currency.signs[storeCode && storeCode.toLowerCase()] ||
    CONFIG_APP.i18n.currency.signs.uk;
  const formattedHTMLTags = {
    strong: (chunk) => <strong>{chunk}</strong>,
    div: (chunk) => <div>{chunk}</div>,
    br: () => <br />,
    a: (chunk) =>
      linkProps ? (
        <Link to={UrlAssembler.collectionDetail(linkProps.id, linkProps.slug)} title="">
          {chunk}
        </Link>
      ) : null,
  };
  const handleClick = () => {
    Cookies.set(
      CONFIG_COOKIES.artistSpotlightCountdownSession.name,
      !hideCodes,
      CONFIG_COOKIES.artistSpotlightCountdownSession,
    );
    setHideCodes(!hideCodes);
  };

  // Update height when message state changes (codes are shown/hidden)
  useEffect(() => {
    if (typeof onHeightChange === 'function') {
      onHeightChange();
    }
  }, [onHeightChange, hideCodes]);

  if (hideCodes) {
    return (
      <div className={classNames(blackFridayCls, blackFridayCondensedCls)}>
        <Button type="link" linkType="strong" width="container" onClick={handleClick}>
          <MediaQuery maxWidth={raScreenSmMax}>
            {(isTablet) => (
              <FormattedMessage
                id={
                  isTablet
                    ? 'components.appMessages.artistSpotlight.titleCondensedMobile'
                    : 'components.appMessages.artistSpotlight.titleCondensed'
                }
                values={formattedHTMLTags}
              >
                {(label: string) => label}
              </FormattedMessage>
            )}
          </MediaQuery>
          <ArrowLightIcon className={blackFridayCountdownCloseCls} />
        </Button>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        blackFridayCls,
        hasCounter ? blackFridayWithCounterCls : blackFridayLightCls,
      )}
    >
      <div className={blackFridayTitleCls}>
        <div className={blackFridayTitleLabelCls}>
          <MediaQuery maxWidth={raScreenSmMax}>
            {(isTablet) => (
              <FormattedMessage
                id={
                  isTablet
                    ? (extended && 'components.appMessages.artistSpotlight.titleExtendedMobile') ||
                      'components.appMessages.artistSpotlight.titleMobile'
                    : (extended && 'components.appMessages.artistSpotlight.titleExtended') ||
                      'components.appMessages.artistSpotlight.title'
                }
                values={formattedHTMLTags}
              >
                {(label: string) => label}
              </FormattedMessage>
            )}
          </MediaQuery>
        </div>

        <MediaQuery maxWidth={raScreenSmMax}>
          <div className={blackFridaySubTitleLabelCls}>
            <FormattedMessage
              id={
                extended
                  ? 'components.appMessages.artistSpotlight.subTitleExtendedMobile'
                  : 'components.appMessages.artistSpotlight.subTitleMobile'
              }
              values={formattedHTMLTags}
            >
              {(label: string) => label}
            </FormattedMessage>
          </div>
        </MediaQuery>
        {hasCounter ? (
          <MediaQuery maxWidth={raScreenSmMax}>
            {(isTablet) => (
              <div className={classNames(blackFridayContentCellCls, blackFridayCountdownCls)}>
                <span className={blackFridayCountdownLabelCls}>
                  {isTablet ? (
                    <FormattedMessage
                      id="components.appMessages.artistSpotlight.countdownLabelTruncated"
                      values={formattedHTMLTags}
                    >
                      {(label: string) => `${label}`}
                    </FormattedMessage>
                  ) : null}
                </span>
                <Countdown
                  variant="outlined"
                  countdownDate={localTime ? convertUTCToLocal(date) : date}
                  labels={{
                    days: (
                      <FormattedMessage id="components.countdown.days">
                        {(label: string) => label}
                      </FormattedMessage>
                    ),
                    hours: (
                      <FormattedMessage id="components.countdown.hours">
                        {(label: string) => label}
                      </FormattedMessage>
                    ),
                    minutes: (
                      <FormattedMessage id="components.countdown.minutes">
                        {(label: string) => label}
                      </FormattedMessage>
                    ),
                    seconds: (
                      <FormattedMessage id="components.countdown.seconds">
                        {(label: string) => label}
                      </FormattedMessage>
                    ),
                  }}
                />
              </div>
            )}
          </MediaQuery>
        ) : null}
      </div>
      <div className={blackFridayContentCls}>
        <div className={blackFridayContentCellCls}>
          {hasCounter ? (
            <MediaQuery maxWidth={raScreenSmMax}>
              {(isMobile: boolean) => (
                <FormattedMessage
                  id={`components.appMessages.artistSpotlight.${
                    isMobile ? 'codeExtendedMobile' : 'codeExtended'
                  }`}
                  tagName="div"
                  values={{
                    currencySign,
                    ...formattedHTMLTags,
                  }}
                />
              )}
            </MediaQuery>
          ) : (
            <FormattedMessage
              id="components.appMessages.artistSpotlight.code"
              tagName="div"
              values={{
                currencySign,
                ...formattedHTMLTags,
              }}
            />
          )}
        </div>
      </div>
      <ArrowUpLightIcon className={blackFridayCountdownCloseCls} onClick={handleClick} />
    </div>
  );
};
