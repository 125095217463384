// extracted by mini-css-extract-plugin
var _1 = "#f4f2f1";
var _2 = "#d23c45";
var _3 = "#0500e8";
var _4 = "#343434";
var _5 = "#7c7876";
var _6 = "Roboto, Arial, Helvetica, clean, sans-serif";
var _7 = "14px";
var _8 = "ra-form-item";
var _9 = "ra-form-item-explain-error";
var _a = "1px solid #343434";
var _b = "1px solid #0500e8";
var _c = "40px";
var _d = "4px 11px";
var _e = "20px";
var _f = "1px solid #f4f2f1";
var _10 = "ra-payment-card-form";
var _11 = "ra-payment-card-form-row";
var _12 = "ra-payment-card-form-input-brand";
var _13 = "ra-payment-card-form-input-cvc-container";
var _14 = "ra-payment-card-form-input";
var _15 = "ra-payment-card-form-input-cvc";
var _16 = "ra-payment-card-form-input-number";
var _17 = "ra-payment-card-form-input-stripe";
var _18 = "ra-payment-card-form-input-validthru";
var _19 = "ra-payment-card-form-input-item-stripe";
var _1a = "#343434";
var _1b = "ra-payment-step-card-row";
var _1c = "ra-payment-step-card-row-item";
var _1d = "ra-payment-step";
export { _1 as "colorBackground", _2 as "colorError", _3 as "colorSuccess", _4 as "colorText", _5 as "colorTextSecondary", _6 as "fontFamily", _7 as "fontSize", _8 as "formItemCls", _9 as "formItemExplainErrorCls", _a as "inputBorder", _b as "inputBorderFocus", _c as "inputHeight", _d as "inputPadding", _e as "inputVerticalMargin", _f as "paymentBoxBorder", _10 as "paymentCardFormCls", _11 as "paymentCardFormRowCls", _12 as "paymentCardInputBrandCls", _13 as "paymentCardInputCVCContainerCls", _14 as "paymentCardInputCls", _15 as "paymentCardInputCvcCls", _16 as "paymentCardInputNumberCls", _17 as "paymentCardInputStripeCls", _18 as "paymentCardInputValidThruCls", _19 as "paymentCardItemStripeCls", _1a as "paymentOptionTabSelectedBkg", _1b as "paymentStepCardRowCls", _1c as "paymentStepCardRowItemCls", _1d as "paymentStepCls" }
